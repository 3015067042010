<template>
    <div class="main">
        <h3>{{ $t('ru-zhu-xu-zhi') }}</h3>
        <div class="rule-content scrollbar" :class="{ isEn: !isChinese }" ref="scrollbar">
            <div v-html="ruleInfo.enName" v-if="!isChinese"></div>
            <div v-if="!isChinese && ruleInfo.enName" class="rule-content-title">
                {{ $i18n.messages.zh['ru-zhu-xu-zhi'] }}
            </div>
            <div v-html="ruleInfo.chName"></div>
        </div>
        <div class="bottom">
            <el-checkbox v-model="checked"
                ><span class="text"
                    >{{ $t('wo-yi-yue-du-bing-tong-yi') }} “ {{ $t('ru-zhu-xu-zhi') }} ” {{ $t('nei-rong') }}</span
                ></el-checkbox
            >
            <el-button type="warning" @click="next">{{ $t('xia-yi-bu') }}</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ruleStep1',
    data() {
        return {
            checked: false,
            ruleText: '',
            ruleTitle: '',
            ruleInfo: {
                chName: '',
                enName: ''
            }
        };
    },
    methods: {
        next() {
            if (this.checked == false) {
                //    this.$message.error('请勾选同意入驻须知内容');
                this.$message({
                    message: this.$t('qing-gou-xuan-tong-yi-ru-zhu-xu-zhi-nei-rong'),
                    type: 'warning'
                });
            } else {
                this.$parent.next();
            }
        }
    },
    mounted() {
        this.$http.get(`/sysConfig/get/RULE_ENTER`).then(res => {
            this.ruleInfo.chName = res.value;
            this.loading = false;
        });
        this.$http.get(`/sysConfig/get/RULE_ENTER_EN`).then(res => {
            this.ruleInfo.enName = res.value;
        });
    }
};
</script>

<style lang="scss" scoped>
.main {
    // padding-top: 40px;
    // padding-bottom: 60px;
    min-height: 50vh;
    box-sizing: border-box;
}

h3 {
    font-size: 18px;
    font-weight: bold;
    color: #292c33;
    line-height: 25px;
    text-align: center;
}

p {
    font-size: 16px;
    color: #292c33;
    line-height: 24px;
    margin-top: 20px;
}

.bottom {
    // display: block;
    padding: 20px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .el-button {
        width: 222px;
        margin-top: 30px;
        padding: 12px 20px;
    }
}
.text {
    color: #ffa526;
}

.rule-content {
    height: calc(100vh - 700px);
    overflow: auto;
    min-height: 400px;
}
</style>
